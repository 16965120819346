<template>
    <div id="content">
        <div class="container-fluid">
            <div class="box">
            	<div class="row">
					<div class="col-auto ml-auto d-flex">
                		<b-button variant="primary" @click="add_licence_appli">
                			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
		                </b-button>
                	</div>
                </div>
                <LoadingSpinner v-if="loading_appli" class="col-12" />
            	<div v-else class="row mt-2" v-for="(appli, index) in licence_appli" :key="index">
            		<div class="col-6">
                		<e-select
                            v-model="appli.type"
                            track-by="apiconfigtype_label"
                            label="apiconfigtype_label"
                            :options="all_appli"
                            :searchable="true"
                            :allow-empty="false"
							:placeholder="$t('global.appli_type')"
                        />
                    </div>

                    <div class="col-6">
						<b-form-input
                            v-if="appli.type && appli.type.apiconfigtype_type == 'TXT'"
							v-model="appli.value"
							:placeholder="$t('global.api_key')"
						></b-form-input>
                        <b-form-checkbox 
                            v-else-if="appli.type && appli.type.apiconfigtype_type == 'BOO'"
                            name="check-button"
                            switch 
                            v-model="appli.value"
                        />
					</div>
            	</div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button v-if="licence_appli.length > 0" variant="primary" rounded-pill @click="saveApplication"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
                    </div>
                </div>
            </div>

            <div class="box" v-if="hasFacturationAccess || hasInvoiceSupplierOCR">
                <div class="row">
                    <div class="col d-flex">
                        <h3>{{ $t('compta.bank_connection') }}</h3> <a href="https://bridgeapi.io/" class="ml-1" target="_blank" rel="noopener noreferrer"><font-awesome-icon :icon="['fas', 'external-link']" /></a>
                    </div>
                    <div class="col-auto ml-auto d-flex">
                        <b-button v-if="bridge_connections.length > 0" variant="secondary" class="mr-2" @click="update_bridge_account">
                            {{ $t('compta.bank_update') }} <font-awesome-icon :icon="['fal', 'sync']" :pulse="processing_update_bridge" class="ml-1"/>
                        </b-button>
                        <b-button variant="primary" @click="add_bridge_connection">
                            {{ $t('global.ajouter') }} <font-awesome-icon v-if="processing_add_bridge" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'plus']" class="ml-1"/>
                        </b-button>
                    </div>
                </div>

                <div class="row">
                    <LoadingSpinner v-if="loading_bridge" class="col-12" />
                    <div v-else class="col-12 mt-2">
                        <div class="row p-3">
                            <div class="col-2">
                                <h3>{{ $t('compta.connection_status') }}</h3>
                            </div>
                            <div class="col-4">
                                <h3>{{ $t('compta.connection_bank') }}</h3>
                            </div>
                            <div class="col-3">
                                <h3>{{ $t('compta.connection_last_update') }}</h3>
                            </div>
                            <div class="col-3">
                            </div>
                        </div>
                        <div class="trigger p-3 row" v-for="connection in bridge_connections" :key="connection.bridgeitem_id">
                            <template v-if="connection.bridgeitem_status == 'waiting'">
                                <div class="col-2">
                                    <b-badge pill class="p-2" variant="info">
                                        <font-awesome-icon class="mr-1" :icon="['fal', 'pause']"/> {{ $t('compta.bank_connection_waiting') }}
                                    </b-badge>
                                </div>
                                <div class="col-4">
                                    <a :href="connection.bridgeitem_redirect_url" class="ml-1" target="_blank" rel="noopener noreferrer">{{ $t('compta.bank_connection_se_connecter') }} <font-awesome-icon :icon="['far', 'arrow-right']" /></a>
                                </div>
                            </template>

                            <template v-else-if="connection.bridgeitem_status == 'connected'">
                                <div class="col-2">
                                    <b-badge pill class="p-2" variant="success">
                                        <font-awesome-icon class="mr-1" :icon="['far', 'check']"/> {{ $t('compta.bank_connection_connected') }}
                                    </b-badge>
                                </div>
                                <div v-if="connection.bridge_bank" class="col-4">
                                    <img :src="connection.bridge_bank.bridgebank_logo_url" style="height: 40px;"> {{ connection.bridge_bank.bridgebank_name }}
                                </div>
                            </template>

                            <template v-else>
                                <div class="col-2">
                                    <b-badge pill class="p-2" variant="danger">
                                        <font-awesome-icon class="mr-1" :icon="['far', 'times']"/> {{ $t('compta.bank_connection_delete') }}
                                    </b-badge>
                                </div>
                                <div v-if="connection.bridge_bank" class="col-4">
                                    <img :src="connection.bridge_bank.bridgebank_logo_url" style="height: 40px;"> {{ connection.bridge_bank.bridgebank_name }}
                                </div>
                            </template>

                            <div class="col-3 d-flex" v-if="connection.bridgeitem_status !== 'deleted'">
                                {{ connection.refreshed_at }} <a href="#" class="ml-2" v-on:click.prevent="refresh_connection(connection.bridgeitem_id)"><font-awesome-icon :icon="['fal', 'sync']"/></a>
                            </div>
                            <div class="col-3">
                                <b-button v-if="connection.bridgeitem_status !== 'deleted'" class="mr-1" @click="confirm_connection_delete(connection.bridgeitem_id)">{{ $t('global.supprimer') }}</b-button>
                                <b-button v-if="connection.bridgeitem_status == 'connected' && connection.bridge_accounts.length > 0" v-b-toggle="'collapse-'+connection.bridgeitem_id" variant="secondary">
                                   {{ $t('compta.bridge_voir_account') }} <font-awesome-icon :icon="['fal', 'angle-down']" />
                                </b-button>
                            </div>
                            <div class="ml-auto col-4 mt-2">
                                <b-collapse :ref="'collapse-'+connection.bridgeitem_id" :id="'collapse-'+connection.bridgeitem_id">
                                    <div class="row" v-for="account in connection.bridge_accounts" :key="account.bridgeaccount_id">
                                        {{ account.bridgeaccount_name }} - {{ account.bridgeaccount_iban }}
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalConfirm
            ref="bridge_delete_modal"
            :text_title="getTrad('compta.delete_bridge_connection_title')"
            :text_question="getTrad('compta.delete_bridge_connection_question')"
            :text_message="getTrad('compta.delete_bridge_connection_message')"
            :text_button_ok="getTrad('global.supprimer')"
            :text_button_cancel="getTrad('modal.general.annuler')"
            :icon="['fal', 'trash-alt']"
            :callback_validation="valid_connection_delete"
        />
    </div>
</template>

<script>
import User from '@/mixins/User'
import OpenBank from '@/mixins/OpenBank'

export default {
    name: 'ApplicationTierce',
    mixins: [User, OpenBank],
   	data () {
		return {
			all_appli: [],
			licence_appli: [],
            loading_appli: false,
            processing: false,
            bridge_connections: [],
            loading_bridge: false,
            processing_add_bridge: false,
            processing_update_bridge: false,
            item_to_delete: null
		}
	},
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            this.loadApplication()
            if(this.hasFacturationAccess || this.hasInvoiceSupplierOCR) {
                this.loadBridgeConnection()
            }
        },

        async loadApplication() {
            this.loading_appli = true
            this.licence_appli = []
            this.all_appli = await this.getAllApplications()
            const appli = await this.getLicenceApplications()
            this.setConfig('api_config', appli)

            appli.forEach(app => {
                let value = app.apiconfig_key
                const type = this.all_appli.find(type => app.apiconfig_type == type.apiconfigtype_key)
                if(type) {
                    if(type.apiconfigtype_type == 'BOO') {
                        value = (value == "1")
                    }
                    this.licence_appli.push({ type, value })
                }
            })
            this.loading_appli = false
        },

        add_licence_appli() {
        	this.licence_appli.push({
        		type: null,
        		value: ''
        	})
        },

        async saveApplication() {
            this.processing = true
            const appli = this.licence_appli.map(app => {
                return {
                    type: app.type.apiconfigtype_key,
                    value: app.value
                }
            })
            await this.saveLicenceApplication(appli)
            this.processing = false
            this.init_component()
        },

        async loadBridgeConnection() {
            this.loading_bridge = true
            const bridge_connections = await this.getBridgeConnection()
            for(let i in bridge_connections) {
                if(bridge_connections[i].refreshed_at) {
                    const date = Date.parseTz(bridge_connections[i].refreshed_at)
                    bridge_connections[i].refreshed_at = this.formatDate(bridge_connections[i].refreshed_at) +' '+ date.getHours() +':'+ date.getMinutes()
                }
            }
            this.bridge_connections = bridge_connections
            this.loading_bridge = false
        },

        async add_bridge_connection() {
            this.processing_add_bridge = true
            let url = await this.addBridgeConnection()
            if(url && url.redirect_url) {
                location.assign(url.redirect_url);
            }
            this.processing_add_bridge = false
            this.loadBridgeConnection()
        },

        async update_bridge_account() {
            this.processing_update_bridge = true
            await this.updateBridgeAccounts()
            this.processing_update_bridge = false
        },

        async refresh_connection(item_id) {
            let res = await this.refreshBridgeConnection(item_id)
            if(res == 'done') {
                this.successToast('compta.bridge_refresh_asked')
            }
            else {
                this.failureToast('compta.bridge_refresh_failed')
            }
        },

        confirm_connection_delete(item_id) {
            this.item_to_delete = item_id
            this.$refs.bridge_delete_modal.openModal()
        },

        async valid_connection_delete() {
            this.$refs.bridge_delete_modal.closeModal()
            await this.deleteBridgeConnection(this.item_to_delete)
            this.loadBridgeConnection()
        }
    },
    computed: {
        hasFacturationAccess() {
            return this.$store.state.userAccess.hasFacturationAccess
        },

        hasInvoiceSupplierOCR() {
            return this.$store.state.userAccess.hasInvoiceSupplierOCR
        },
    },
    components: {
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
    }
};
</script>